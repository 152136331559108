import { Controller } from '@hotwired/stimulus'
import Axios from 'axios'
import '@google-pay/button-element'
import useGA4Helper from '../apps/main/src/hooks/useGA4Helper'
import useInternalEvent from '../apps/main/src/hooks/useInternalEvent'
const { GA4TrackPaymentInfo } = useGA4Helper()
const { sendEvent } = useInternalEvent()

// https://developers.google.com/pay/api/web/guides/tutorial
export default class extends Controller {
  connect() {
    const button = document.querySelector('google-pay-button')
    this.userId = button.dataset.userId
    const paymentRequest = JSON.parse(button.dataset.googlePaymentRequestConfig)
    console.log('paymentrequest', paymentRequest)

    button.paymentRequest = paymentRequest

    button.addEventListener('click', (_event) => {
      this.createOrder()
    })

    button.addEventListener('loadpaymentdata', (event, controller = this) => {
      console.log('load payment data', event.detail)
      return new Promise(function (resolve, _reject) {
        resolve(controller.makePayment(event.detail))
      })
    })

    button.addEventListener('onPaymentAuthorized', (event) => {
      console.log('on payment authorized', event.detail)
      this.sendEvent('onPaymentAuthorized', { event_detail: event.detail })
    })

    button.addEventListener('onPaymentDataChanged', (event) => {
      console.log('on payment data changed', event.intermediatePaymentData)
      this.sendEvent('onPaymentDataChanged', { event_detail: event.intermediatePaymentData })
    })

    button.addEventListener('error', (event) => {
      console.log('on error', event.detail)
      this.sendEvent('error listened', { event_detail: event.detail })
    })

    const form = document.querySelector('form[data-form-checkout]')
    form.addEventListener('change', (_event) => {
      console.log('form changed')
      const selectedFields = form.querySelectorAll(
        "input[name='checkout[customer_email]'],fieldset:not(.hidden) input:required"
      )
      const selectedFieldsArr = Array.from(selectedFields)
      const gPayButton = button.querySelector('button')
      if (selectedFieldsArr.every(this.isCompleted)) {
        button.classList.remove('opacity-50')
        if (gPayButton) gPayButton.disabled = false
      } else {
        button.classList.add('opacity-50')
        if (gPayButton) gPayButton.disabled = true
      }
    })
  }

  isCompleted(el, _index, _arr) {
    return el.value.trim() !== ''
  }

  csrfToken() {
    return document.querySelector('meta[name=csrf-token]').content
  }

  headers() {
    return { 'content-type': 'application/json', 'X-CSRF-Token': this.csrfToken() }
  }

  createOrder() {
    GA4TrackPaymentInfo('Google Pay')
    this.sendEvent('createOrder')
    const form = document.querySelector('form[data-form-checkout]')
    const json = this.formDataObject(form)

    return Axios.post('/payment/google_pay/create_order', json, { headers: this.headers() })
      .then((response) => {
        if (response.status === 200) {
          this.sendEvent('createOrderSuccess')
          return response.data
        } else {
          console.log('Order Creation Error', { errors: response.errors })
          this.sendEvent('createOrderError', { errors: response.errors })
          throw new Error(response.statusText)
        }
      })
      .catch((error) => {
        // TODO: we need to close the Google modal here and display any server-side errors
        // otherwise we are going to get _lots_ of complaints
        const errorMessages = error?.response?.data?.errors
        this.sendEvent('createOrderError.catch', { errorMessages: errorMessages })
        return errorMessages
      })
  }

  makePayment(data) {
    this.sendEvent('makePayment')
    const json = {
      payment_token: data.paymentMethodData.tokenizationData.token,
      payment_provider_response: JSON.stringify(data)
    }

    return Axios.post('/payment/google_pay/make_payment', json, { headers: this.headers() })
      .then((response) => {
        if (response.status === 200) {
          this.sendEvent('makePaymentSuccess')
          return (window.location.href = response.data.redirect_url)
        } else {
          console.log('Order Payment Error', { errors: response.errors })
          this.sendEvent('makePaymentError', { errors: response.errors })
          throw new Error(response.statusText)
        }
      })
      .catch((error) => {
        const errorMessages = error?.response?.data?.errors
        this.sendEvent('makePaymentError.catch', { errorMessages: errorMessages })
        return errorMessages
      })
  }

  // TODO: there must be a built-in or lodash function that does this?
  deepSet(obj, path, value) {
    if (Object(obj) !== obj) return obj // When obj is not an object
    // If not yet an array, get the keys from the string-path
    if (!Array.isArray(path)) path = path.toString().match(/[^.[\]]+/g) || []
    path.slice(0, -1).reduce(
      (
        a,
        c,
        i // Iterate all of them except the last one
      ) =>
        Object(a[c]) === a[c] // Does the key exist and is its value an object?
          ? // Yes: then follow that path
            a[c]
          : // No: create the key. Is the next key a potential array-index?
            (a[c] =
              Math.abs(path[i + 1]) >> 0 === +path[i + 1]
                ? [] // Yes: assign a new array object
                : {}), // No: assign a new plain object
      obj
    )[path[path.length - 1]] = value // Finally assign the value to the last key
    return obj // Return the top-level object to allow chaining
  }

  formDataObject(form) {
    const formData = new FormData(form)
    const root = {}
    for (const [path, value] of formData) {
      this.deepSet(root, path, value)
    }
    return root
  }

  onLoadPaymentData(paymentData) {
    console.log('load payment data', paymentData)
    this.sendEvent('onLoadPaymentData', { paymentData: paymentData })
  }

  onError(error) {
    this.sendEvent('onError', { error: error })
    console.log('error', error)
  }

  onPaymentAuthorized(paymentData) {
    this.sendEvent('onPaymentAuthorized unused callback', { paymentData: paymentData })

    return {
      transactionState: 'SUCCESS'
    }
  }

  // This function used to sent to Sentry.io, then HoneyBadger. Both of which proved to be useless.
  // So we will keep the code around and send it to a simple events table that we own
  sendEvent(name, data) {
    const details = Object.assign({ user_id: this.userId }, data)
    sendEvent(name, details)
  }
}
