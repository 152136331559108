import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys, KeepAlive as _KeepAlive, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center mb-1 py-4" }
const _hoisted_2 = {
  ref: "searchForm",
  action: "/prints",
  method: "get",
  autocomplete: "off",
  class: "flex mb-8"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  ref: "tags",
  class: "tags"
}
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "mt-5" }
const _hoisted_8 = { class: "grid grid-cols-1 md:grid-cols-3 gap-8 items-end" }
const _hoisted_9 = ["href"]
const _hoisted_10 = ["src", "alt", "title"]
const _hoisted_11 = { class: "artwork-card-info my-6" }
const _hoisted_12 = { class: "flex justify-between" }
const _hoisted_13 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode($setup["KmModal"], {
      open: $setup.searchModalState,
      onClose: $setup.toggleSearchModal
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[8] || (_cache[8] = _createElementVNode("p", { class: "typeset-8" }, "Search", -1)),
          _createVNode($setup["KmIcon"], {
            class: "h-[14px] w-[14px] cursor-pointer",
            name: "close",
            onClick: _cache[0] || (_cache[0] = $event => ($setup.toggleSearchModal()))
          })
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("form", _hoisted_2, [
          _createElementVNode("button", {
            class: "button button-grey",
            onClick: _cache[1] || (_cache[1] = _withModifiers($event => ($setup.clickSearch()), ["prevent"]))
          }, [
            ($setup.isSearching)
              ? (_openBlock(), _createBlock($setup["KmIcon"], {
                  key: 0,
                  name: "spinner",
                  class: "h-4w-4mr-2 animate-spin"
                }))
              : (_openBlock(), _createBlock($setup["KmIcon"], {
                  key: 1,
                  name: "search"
                }))
          ]),
          _createElementVNode("input", {
            ref: "input",
            type: "text",
            name: "q",
            class: "w-full",
            onKeyup: [
              _withModifiers($setup.onKeyUp, ["prevent"]),
              _cache[2] || (_cache[2] = _withKeys(_withModifiers($event => ($setup.onNavigate('down')), ["prevent"]), ["down"])),
              _cache[3] || (_cache[3] = _withKeys(_withModifiers($event => ($setup.onNavigate('left')), ["prevent"]), ["left"])),
              _cache[4] || (_cache[4] = _withKeys(_withModifiers($event => ($setup.onNavigate('right')), ["prevent"]), ["right"])),
              _cache[5] || (_cache[5] = _withKeys(_withModifiers($event => ($setup.onNavigate('up')), ["prevent"]), ["up"]))
            ],
            onClick: _cache[6] || (_cache[6] = $event => ($setup.resetTags()))
          }, null, 544)
        ], 512),
        ($setup.showDefault)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(), _createBlock(_KeepAlive, null, [
                _createVNode($setup["KmSearchDefaultView"])
              ], 1024))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "typeset-8 mb-3" }, "Suggested", -1)),
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.combinedArtistsCategoriesPages, (item) => {
                  return (_openBlock(), _createElementBlock("a", {
                    key: item.id,
                    href: item.url
                  }, _toDisplayString(item.full_name || item.title), 9, _hoisted_6))
                }), 128))
              ], 512),
              _withDirectives(_createElementVNode("div", _hoisted_7, [
                _cache[9] || (_cache[9] = _createElementVNode("div", { class: "typeset-8 mb-3" }, "Suggested artworks", -1)),
                _createElementVNode("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.artworks.results, (artwork) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: artwork.id
                    }, [
                      _createElementVNode("a", {
                        href: artwork.url,
                        class: "artwork-card-img"
                      }, [
                        _createElementVNode("img", {
                          src: artwork.default_products[0].image_url_lowres,
                          alt: `${artwork.title} by ${artwork.artist.full_name}`,
                          title: `${artwork.title} by ${artwork.artist.full_name}`
                        }, null, 8, _hoisted_10)
                      ], 8, _hoisted_9),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("a", {
                            class: "truncate",
                            href: artwork.href
                          }, _toDisplayString(artwork.title), 9, _hoisted_13)
                        ]),
                        _createElementVNode("p", null, "£" + _toDisplayString(artwork.default_products[0].price / 100) + ", framed", 1)
                      ]),
                      _createVNode($setup["KmTags"], {
                        tags: artwork.tags,
                        "created-at": artwork.created_at,
                        clamp: true,
                        class: "overflow-hidden"
                      }, null, 8, ["tags", "created-at"])
                    ]))
                  }), 128))
                ]),
                _createElementVNode("a", {
                  class: "button mt-10 cursor-pointer",
                  onClick: _cache[7] || (_cache[7] = $event => ($setup.searchForm.submit()))
                }, "Explore all ‘" + _toDisplayString($setup.input.value) + "’ prints", 1)
              ], 512), [
                [_vShow, $setup.artworks.results?.length]
              ])
            ]))
      ]),
      _: 1
    }, 8, ["open"])
  ]))
}