<template>
  <div>
    <KmModal :open="searchModalState" @close="toggleSearchModal">
      <template #header>
        <div class="flex justify-between items-center mb-1 py-4">
          <p class="typeset-8">Search</p>
          <KmIcon class="h-[14px] w-[14px] cursor-pointer" name="close" @click="toggleSearchModal()" />
        </div>
      </template>

      <form ref="searchForm" action="/prints" method="get" autocomplete="off" class="flex mb-8">
        <button class="button button-grey" @click.prevent="clickSearch()">
          <KmIcon v-if="isSearching" name="spinner" class="h-4w-4mr-2 animate-spin"></KmIcon>
          <KmIcon v-else name="search"></KmIcon>
        </button>
        <input
          ref="input"
          type="text"
          name="q"
          class="w-full"
          @keyup.prevent="onKeyUp"
          @keyup.down.prevent="onNavigate('down')"
          @keyup.left.prevent="onNavigate('left')"
          @keyup.right.prevent="onNavigate('right')"
          @keyup.up.prevent="onNavigate('up')"
          @click="resetTags()"
        />
      </form>

      <div v-if="showDefault">
        <KeepAlive>
          <KmSearchDefaultView />
        </KeepAlive>
      </div>

      <div v-else>
        <div class="typeset-8 mb-3">Suggested</div>
        <div ref="tags" class="tags">
          <a v-for="item in combinedArtistsCategoriesPages" :key="item.id" :href="item.url">
            {{ item.full_name || item.title }}
          </a>
        </div>

        <div v-show="artworks.results?.length" class="mt-5">
          <div class="typeset-8 mb-3">Suggested artworks</div>
          <div class="grid grid-cols-1 md:grid-cols-3 gap-8 items-end">
            <div v-for="artwork in artworks.results" :key="artwork.id">
              <a :href="artwork.url" class="artwork-card-img">
                <img
                  :src="artwork.default_products[0].image_url_lowres"
                  :alt="`${artwork.title} by ${artwork.artist.full_name}`"
                  :title="`${artwork.title} by ${artwork.artist.full_name}`"
                />
              </a>

              <div class="artwork-card-info my-6">
                <div class="flex justify-between">
                  <a class="truncate" :href="artwork.href">{{ artwork.title }}</a>
                </div>

                <p>£{{ artwork.default_products[0].price / 100 }}, framed</p>
              </div>

              <KmTags
                :tags="artwork.tags"
                :created-at="artwork.created_at"
                :clamp="true"
                class="overflow-hidden"
              ></KmTags>
            </div>
          </div>

          <a class="button mt-10 cursor-pointer" @click="searchForm.submit()">Explore all ‘{{ input.value }}’ prints</a>
        </div>
      </div>
    </KmModal>
  </div>
</template>

<script lang="ts" setup>
import { isEmpty } from 'lodash-es'
import { onMounted, ref, watch } from 'vue'
import KmIcon from './components/general/KmIcon.vue'
import KmModal from './components/general/KmModal.vue'
import KmSearchDefaultView from './components/general/KmSearchDefaultView.vue'
import KmTags from './components/general/KmTags.vue'
import useSearch from './hooks/useSearch'
import useGA4 from '../../utils/useGA4'
import type { Artwork } from './interfaces/ProductInterfaces'

const { fetchArtists, fetchArtworks, fetchCategories, fetchPages } = useSearch()
const { trackSearchGA4 } = useGA4()
const artworks = ref([])
const combinedArtistsCategoriesPages = ref([])
const input = ref(null)
const isSearching = ref(false)
const searchForm = ref()
const searchModalState = ref(true)
const searchText = ref('')
const selectedSection = ref(0)
const selectedTag = ref(0)
const showDefault = ref(true)
const tags = ref(null)

onMounted(() => {
  input.value.focus()
  handleBackground(searchModalState.value)
})

function setDefault() {
  showDefault.value =
    input?.value?.value == '' || (isEmpty(combinedArtistsCategoriesPages.value) && isEmpty(artworks.value.results))
}

watch(searchText, (newVal) => {
  doSearch(newVal)
  setDefault()
})

function onKeyUp(evt) {
  searchText.value = evt.target.value
}

function onNavigate(direction) {
  switch (direction) {
    case 'left':
      if (selectedTag.value > 1) selectedTag.value--
      break

    case 'right':
      selectedTag.value++
      break

    case 'up':
      if (selectedSection.value > 0) {
        selectedSection.value--
        selectedTag.value = 1
      }
      break

    case 'down':
      selectedSection.value++
      selectedTag.value = 1
      break
  }
}

watch([selectedSection, selectedTag], (newVal, oldVal) => {
  const [oldSection, oldTag] = oldVal
  const [newSection, newTag] = newVal

  selectTag(oldSection, oldTag, '')
  selectTag(newSection, newTag, 'tag-selected')
})

function resetTags() {
  selectedSection.value = 0
  selectedTag.value = 0
}

function selectTag(section: string, tag: string, klass: string) {
  const ele = document.getElementById(`tag-${section}-${tag}`)

  if (ele) {
    ele.className = ''
    ele.classList.add(klass)
  }
}

const doSearch = function (text: string): void {
  isSearching.value = true
  searchArtworks(text)
  Promise.all([searchArtists(text), searchCategories(text), searchPages(text)]).then((results) => {
    combinedArtistsCategoriesPages.value = results
      .flat()
      .filter((val, index, arr) => arr.findIndex((val2) => val2.url === val.url) === index)

    isSearching.value = false
  })
}

async function searchArtworks(text: string): Promise<void> {
  artworks.value.results = await fetchArtworks(text).then((artworks) => {
    return artworks.results.map((artwork: Artwork) => {
      artwork.url = `${artwork.url}#${artwork.default_products[0].id}`
      return artwork
    })
  })
}

function searchPages(text: string): Promise<any> {
  return fetchPages(text)
}

function searchArtists(text: string): Promise<any> {
  return new Promise((resolve) => {
    fetchArtists(text).then((artists) => {
      resolve(
        artists.results
          .filter((artist) => artist.num_artworks > 0)
          .map((item) => ({ ...item, suppresed: false, url: `/prints/${item.slug}` }))
      )
    })
  })
}

function searchCategories(text: string): Promise<any> {
  return new Promise((resolve) => {
    fetchCategories(text).then((categories) => {
      resolve(
        categories
          .filter((category) => category.num_artworks > 0 && category.suppressed !== true)
          .map((item) => ({ ...item, url: `/prints/${item.slug}` }))
      )
    })
  })
}

function toggleSearchModal() {
  searchModalState.value = !searchModalState.value
  handleBackground(searchModalState.value)
}

function handleBackground(isOpen: boolean) {
  const root = document.documentElement
  if (isOpen == true) {
    root.classList.add('modal-open')
  } else {
    root.classList.remove('modal-open')
  }
}

function clickSearch() {
  if (input?.value?.value != '') {
    trackSearchGA4(input.value.value)
    searchForm.value.submit()
  }
}
</script>
