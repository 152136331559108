import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['processingMessage', 'errorMessage', 'paymentProviderToken', 'checkoutForm']

  connect() {
    try {
      window.Klarna.Payments.init({
        client_token: window.klarnaToken
      })
      this.load()
    } catch (_) {
      // Send alert to JS logging thing (or something)
    }
  }

  load() {
    window.Klarna.Payments.load(
      {
        container: '#klarna-payments-container',
        payment_method_category: 'pay_over_time'
      },
      function (res) {
        if (res.show_form === true) {
          // Do nothing. The form will display automatically
        } else {
          // Hide the form and show error message. Also send alert to non-existing JS logging thing that doesn't exist
        }
      }
    )
  }

  authorize() {
    window.Klarna.Payments.authorize(
      {
        payment_method_category: 'pay_over_time'
      },
      window.klarnaOrder,
      function (res) {
        if (res.approved === true && res.authorization_token) {
          try {
            this.paymentProviderTokenTarget.value = res.authorization_token
            this.checkoutFormTarget.submit()
          } catch (_e) {
            // debugger
          }
        } else {
          if (res.show_form === true) {
            // user needs to correct card/payment details
            // resetProcessing()
          } else {
            // errorResult()
          }
        }
      }.bind(this)
    )
  }
}
