import useHttps from './useHttps'

export default function useInternalEvent() {
  const { post } = useHttps()

  function sendEvent(name: string, details: any) {
    const url = '/api/events'
    const body = {
      event: {
        name: name,
        details: details
      }
    }

    return post(url, body)
  }

  return {
    sendEvent
  }
}
