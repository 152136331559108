import { Controller } from '@hotwired/stimulus'
import Axios from 'axios'
import { map, merge } from 'lodash-es'
import useGA4Helper from '../apps/main/src/hooks/useGA4Helper'
import useCheckout from '../apps/main/src/hooks/useCheckout'
const { GA4TrackPaymentInfo } = useGA4Helper()
const { updateSummary } = useCheckout()

const MERCHANT_ID = KM.Config.apple_pay.merchant_id
const APPLE_PAY_VERSION = 12
const PAYMENT_REQUEST_URL = '/checkout/apple_pay/payment_request'
const VALIDATE_MERCHANT_SESSION_URL = '/checkout/apple_pay/validate_merchant'
const UPDATE_SHIPPING_URL = '/checkout/apple_pay/update_shipping'
const COMPLETE_PAYMENT_URL = '/payment/apple_pay/complete'
const APPLE_PAY_BUTTON = document.querySelector('#apple-pay-button')

export default class extends Controller {
  async connect() {
    if (typeof ApplePaySession === 'undefined') return
    if (!ApplePaySession.canMakePayments()) return

    // Show Apple Pay option if we're on an iOS device
    const ele = document.getElementById('payment-type-applepay')
    if (ele == null) return null

    ele.classList.remove('hidden')

    // Check if customer has a valid card in their Apple Wallet
    // If they do we're required to choose the ApplePay option
    const promise = ApplePaySession.canMakePaymentsWithActiveCard(MERCHANT_ID)
    promise.then(function (canMakePayments) {
      if (!canMakePayments) return

      const ele = document.getElementById('checkout_payment_type_payment_apple_pay')
      if (ele != null) {
        ele.closest('label')?.click()
      }
    })

    const paymentRequest = await this.createPaymentRequest().catch(function (error) {
      if (error.response.data.redirect_url) {
        window.location.href = error.response.data.redirect_url
      }
    })

    APPLE_PAY_BUTTON.addEventListener(
      'click',
      function () {
        const session = new ApplePaySession(APPLE_PAY_VERSION, paymentRequest)
        session.begin()
        GA4TrackPaymentInfo('ApplePay')

        session.onvalidatemerchant = function (event) {
          this.validateMerchantSession(event.validationURL, function (merchantSession) {
            session.completeMerchantValidation(merchantSession)
          }).catch(function (error) {
            if (error.response.data.redirect_url) {
              window.location.href = error.response.data.redirect_url
            }
          })
        }.bind(this)

        session.onshippingmethodselected = function (event) {
          this.updateShippingMethod(event.shippingMethod.identifier, function (response) {
            let payload = response
            const errors = map(response.errors, (error) => new ApplePayError(...error))

            if (undefined === response.errors) {
              payload = merge(payload, { status: ApplePaySession.STATUS_SUCCESS })
              session.completeShippingMethodSelection(payload)
            } else {
              payload = merge(payload, { status: ApplePaySession.STATUS_FAILURE, errors: errors })
              session.completeShippingMethodSelection(payload)
            }
          })
        }.bind(this)

        session.onshippingcontactselected = function (event) {
          this.updateShippingContact(event.shippingContact, function (response) {
            let payload = response
            const errors = map(response.errors, (error) => new ApplePayError(...error))

            if (undefined === response.errors) {
              payload = merge(payload, { status: ApplePaySession.STATUS_SUCCESS })
              session.completeShippingContactSelection(payload)
            } else {
              payload = merge(payload, { status: ApplePaySession.STATUS_FAILURE, errors: errors })
              session.completeShippingContactSelection(payload)
            }
          })
        }.bind(this)

        session.onpaymentauthorized = function (event) {
          this.completePayment(event.payment, function (response) {
            let payload = response
            const errors = map(response.errors, (error) => new ApplePayError(...error))

            if (response.status === 'success') {
              session.completePayment(ApplePaySession.STATUS_SUCCESS)

              // Redirect to completed order page
              window.location.href = response.checkout_complete_url
            } else {
              payload = merge(payload, { status: ApplePaySession.STATUS_FAILURE, errors: errors })
              session.completePayment(payload)
            }
          })
        }.bind(this)

        //  This is called when user dismisses the payment modal
        session.oncancel = (_event) => {}
      }.bind(this)
    )
  }

  csrfToken() {
    return document.querySelector('meta[name=csrf-token]').content
  }

  createPaymentRequest() {
    return Axios.get(PAYMENT_REQUEST_URL).then((response) => {
      return response.data
    })
  }

  validateMerchantSession(validationUrl, callback) {
    return Axios.post(
      VALIDATE_MERCHANT_SESSION_URL,
      { validation_url: validationUrl },
      { headers: { 'X-CSRF-Token': this.csrfToken() } }
    ).then(function (response) {
      callback(response.data)
    })
  }

  updateShippingMethod(shippingMethod, callback) {
    return Axios.post(
      UPDATE_SHIPPING_URL,
      {
        checkout: {
          shipping_method: shippingMethod
        }
      },
      { headers: { 'X-CSRF-Token': this.csrfToken() } }
    ).then(function (response) {
      callback(response.data)
    })
  }

  updateShippingContact(shippingContact, callback) {
    return Axios.post(
      UPDATE_SHIPPING_URL,
      {
        checkout: {
          delivery_address: {
            country_code: shippingContact.countryCode
          }
        }
      },
      { headers: { 'X-CSRF-Token': this.csrfToken() } }
    ).then(function (response) {
      const countryCode = document.getElementById('checkout_delivery_address_country_code')
      countryCode.value = shippingContact.countryCode
      updateSummary()
      callback(response.data)
    })
  }

  completePayment(paymentDetails, callback) {
    return Axios.post(
      COMPLETE_PAYMENT_URL,
      { payment_details: paymentDetails },
      { headers: { 'X-CSRF-Token': this.csrfToken() } }
    ).then(function (response) {
      callback(response.data)
    })
  }
}
