import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'payment_google_pay',
    'payment_google_pay_button',
    'payment_card',
    'payment_paypal',
    'payment_paypal_button',
    'payment_klarna',
    'payment_klarna_button',
    'payment_apple_pay',
    'payment_apple_pay_button'
  ]

  connect() {
    const paymentType = this.element.querySelector('[name="checkout[payment_type]"]:checked')?.value
    if (paymentType) this.changePaymentType(paymentType)
  }

  changePayment(ele) {
    this.changePaymentType(ele.currentTarget.value)
  }

  hideIndividualPaymentButtons() {
    this.hideGooglePayButton()
    this.payment_apple_pay_buttonTarget.classList.add('hidden')
    this.payment_paypal_buttonTarget.classList.add('hidden')
    this.payment_klarna_buttonTarget.classList.add('hidden')
  }

  hideGooglePayButton() {
    const button = document.querySelector('[data-payment-options-target="payment_google_pay_button"]')
    if (button) button.classList.add('hidden')
  }

  showGooglePayButton() {
    const button = document.querySelector('[data-payment-options-target="payment_google_pay_button"]')
    if (button) button.classList.remove('hidden')
  }

  selectPaymentType(paymentType) {
    Array.from(this.element.children).forEach(function (e) {
      e.classList.remove('selected')
    })
    this[`${paymentType}Target`].classList.add('selected')
  }

  changePaymentType(paymentType) {
    this.hideIndividualPaymentButtons()
    this.selectPaymentType(paymentType)

    switch (paymentType) {
      case 'payment_google_pay':
        this.showGooglePayButton()
        this.showCheckoutForm()
        this.hideCheckoutButton()
        break
      case 'payment_paypal':
        this.hideCheckoutForm()
        this.payment_paypal_buttonTarget.classList.remove('hidden')
        break
      case 'payment_apple_pay':
        this.payment_apple_pay_buttonTarget.classList.remove('hidden')
        this.hideCheckoutForm()
        break
      case 'payment_klarna':
        this.payment_klarna_buttonTarget.classList.remove('hidden')
        this.showCheckoutForm()
        this.showCheckoutButton()
        break
      case 'payment_card':
        this.showCheckoutForm()
        this.showCheckoutButton()
        break
    }
  }

  hideCheckoutForm() {
    const formWrapper = document.getElementById('checkout-form-wrapper')
    formWrapper.classList.add('hidden')
  }

  hideCheckoutButton() {
    const button = document.getElementById('continue-to-payment-button')
    button.classList.add('hidden')
  }

  showCheckoutForm() {
    const formWrapper = document.getElementById('checkout-form-wrapper')
    formWrapper.classList.remove('hidden')
  }

  showCheckoutButton() {
    const button = document.getElementById('continue-to-payment-button')
    button.classList.remove('hidden')
  }
}
